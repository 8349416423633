:root {
    --background: #ffffff;
    --background-alpha-09: rgba(255, 255, 255, 0.9);
    --accent: #000000;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'STIX Two Text',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.text-main {
    color: var(--accent);
    font-size: 14pt;
    text-align: center;
    vertical-align: center;
    font-family: "Quicksand", 'PT Serif', serif;
    max-width: 450pt;
}

.text-small {
    color: var(--accent);
    font-size: 18pt;
    text-align: center;
    vertical-align: center;
    font-family: "Quicksand", 'PT Serif', serif;
}

.text-menu {
    color: var(--accent);
    font-size: 12pt;
    text-align: center;
    vertical-align: center;
    font-family: 'Quicksand', 'Raleway', sans-serif;
    text-decoration: none;
}

.menu-icon-mobile {
    color: var(--accent);
}

.text-session-title {
    color: var(--accent);
    font-size: 20pt;
    vertical-align: center;
    font-family: "Quicksand", 'PT Serif', serif;
}

.text-session-info {
    color: var(--accent);
    font-size: 12pt;
    vertical-align: center;
    font-family: 'Quicksand', 'Raleway', sans-serif;
    text-decoration: none;
}

.text-about-title {
    color: var(--accent);
    font-size: 24pt;
    vertical-align: center;
    font-family: "Quicksand", 'Oswald', serif;
}

.text-about-body {
    white-space: pre-wrap;
    color: var(--accent);
    font-size: 12pt;
    vertical-align: center;
    font-family: 'Quicksand', 'Raleway', sans-serif;
    text-decoration: none;
}

.text-contacts-link {
    color: var(--accent);
    font-size: 12pt;
    vertical-align: center;
    font-family: 'Quicksand', 'Raleway', sans-serif;
    text-decoration: none;
    padding-inline-end: 8pt;
    padding-inline-start: 8pt;
}

.text-contacts-postfix {
    color: #bb7118;
    font-size: 12pt;
    vertical-align: center;
    font-family: 'Quicksand', 'Raleway', sans-serif;
    text-decoration: none;
}

.text-bottom-menu {
    color: var(--accent);
    font-size: 10pt;
    text-align: center;
    vertical-align: center;
    font-family: 'Quicksand', 'Raleway', sans-serif;
    text-decoration: none;
}

.text-selection-menu-title {
    padding-bottom: 10px;
    padding-top: 10px;
    color: var(--background);
    font-size: 16pt;
    font-family: 'Quicksand', 'Raleway', sans-serif;
    text-decoration: none;
    text-align: center;
}

.text-selection-menu-photo-title {
    padding-bottom: 10px;
    padding-top: 10px;
    color: var(--accent);
    font-size: 16pt;
    font-family: 'Quicksand', 'Raleway', sans-serif;
    text-decoration: none;
    text-align: center;
}


.text-selection-menu {
    color: var(--accent);
    font-size: 16pt;
    font-family: 'Quicksand', 'Raleway', sans-serif;
    text-decoration: none;
    text-align: center;
    background-color: green;
}

.mobile-menu-separator {
    width: 1px;
    margin-inline-start: 2vw;
    margin-inline-end: 2vw;
    height: 14px;
    background-color: var(--accent);
}
